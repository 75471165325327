import React from 'react';

const Index = () => {
  return (
    <nav className="relative select-none bg-grey lg:flex justify-between lg:items-stretch w-full mb-24">
      <ul className="flex flex-no-shrink items-stretch h-12">
        <li className="navbar_item">
          <a href="/" className={`${window.location.pathname === "/" ? "font-bold" : "" }`}>Accueil</a>
        </li>
        <li className="navbar_item">⚔</li>
        <li  className="navbar_item">
          <a href="/blog" className={`${window.location.pathname === "/blog" ? "font-bold" : "" }`}>Blog</a>
        </li>
        <li className="navbar_item">⚔</li>
        <li  className="navbar_item">
          <a href="/cv" className={`${window.location.pathname === "/cv" ? "font-bold" : "" }`}>CV</a>
        </li>
      </ul>
      <span className="flex flex-no-shrink items-stretch h-12">
        <a href="https://github.com/LeoGoubard" className="navbar_item" target="_blank" rel="noopener noreferrer"><img width={24} src="/images/github-32.png" alt="Github"/></a>
        <a href="https://www.linkedin.com/in/leogoubard/" className="navbar_item" target="_blank" rel="noopener noreferrer"><img width={24} src="/images/linkedin-32.png" alt="LinkedIn"/></a>
      </span>
    </nav>

  );
}

export default Index;
