import React from 'react'
import Helmet from "helmet";

const Index = () => {
  return (
    <div>
      <Helmet>
        Leo Goubard Développeur Fullstack - Resume
        <meta name="description" content="Mes experiences professionnelles en tant que développeur" />
      </Helmet>
      <div class="flex justify-between mb-3">
        <b style={{color: "#ff2029"}}>
          ⚠️ À l'écoute du marché ⚠️
        </b>
        <a href="/files/Freelance Javascript - Leo Goubard.pdf" className="w-12" target="_blank" rel="noopener noreferrer">
          <img src="/images/pdf.png" alt="PDF" />
        </a>
      </div>
      <h2 className="text-4xl font-bold">Expériences professionnelles en tant que Développeur</h2>
      <ul class="block list-none ml-5 mt-5">
        <li className="mb-[1.25em]" itemscope="" itemtype="http://schema.org/Organization">
          <h5 className="float-right text-lg">08/2023 - 12/2023</h5>
          <h3 className="font-bold text-xl line-clamp-1"><span itemprop="jobTitle">Developpeur Fullstack React/Python/TypeScript</span></h3>
          <h4><a href="https://www.activinnov.com/" target="_blank" rel="noopener noreferrer"><span itemprop="name" className="text-lg font-semibold">SmartTravel</span></a>, Nantes (44)</h4>

          <p itemprop="description" class="nospace">
            </p><p>Application de planification de voyage via IA (Openai),
            générant un maximum d'informations utiles tels que le
            documents nécessaires, les activités à la journée, les prix de
            chaque étapes du voyages, la réservation des
            transports/hébergements/activités.
            l'application est actuellement en Bêta test.</p>

            <div className=" mt-5 keyword">
              <p>Réalisations technique :</p>
              <ul className="mt-5">
              <li>✔ Backend en Python</li>
              <li>✔ BDD PostgreSQL</li>
              <li>✔ Déploiement Azure</li>
              <li>✔ Communication Openai</li>
              <li>✔ Gestionnaire de connexion utilisateurs</li>
              <li>✔ CRUD de creations de voyages et d'articles de blog</li>
              <li>✔ Gestionnaire d'acces de contenus selon le Rôle utilisateurs</li>
              <li>✔ Visualisation data des différentes requêtes de l'application</li>
              <li>✔ Panneaux Utilisateurs, Admin</li>
              <li>✔ Intégration Tailwind/JSX</li>
              </ul>
            </div>
          <p></p>
        </li>
        <li className="mb-[1.25em]" itemscope="" itemtype="http://schema.org/Organization">
          <h5 className="float-right text-lg">01/2023 - 07/2023</h5>
          <h3 className="font-bold text-xl line-clamp-1"><span itemprop="jobTitle">Developpeur Fullstack React/Node/TypeScript</span></h3>
          <h4><a href="https://www.bedrockstreaming.com/" target="_blank" rel="noopener noreferrer"><span itemprop="name" className="text-lg font-semibold">TeaTime</span></a>, Nantes (44)</h4>

          <p itemprop="description" class="nospace">
          Intervention au sein d'un groupe de 3 développeurs, le projet
          consistait à établir une application de coworking en 3D,
          l'utilisateur incarne un personnage customisable ayant accès à
          une multitude de salles de réunion, salle de jeux et d'une
          communication de proximité avec les autres utilisateurs.
            </p><div className=" mt-5 keyword">
              <p>Réalisation technique :</p>
              <ul className="mt-5">
                <li>✔ Participation/Animation cérémonies Agile</li>
                <li>✔ Analyse des besoins techniques</li>
                <li>✔ Propositions de choix techniques/Architecture/Designs</li>
                <li>✔ Audit Technique et édition de documentation</li>
                <li>✔ Chiffrage/Estimations des difficultés techniques de
                développements</li>
                <li>✔ Relecture et contrôle de la qualité de code de 2 développeurs</li>
                <li>✔ Création d'une API node, une communication à la base
                Postegre avec GraphQL</li>
                <li>✔ Intégration d'une plateforme 3D simple via Blender et
                modelisation lowPoly des personnages et des modifications
                possibles</li>
                <li>✔ Interface de modifications du personnage</li>
                <li>✔ Gestion via JsonWebToken de la connexion/inscription de</li>
                l'utilisateur
                <li>✔ Application de la partie "multijoueur" (webSocket)</li>
              </ul>
            </div>
          <p></p>
        </li>
        <li className="mb-[1.25em]" itemscope="" itemtype="http://schema.org/Organization">
          <h5 className="float-right text-lg">08/2022 - 11/2022</h5>
          <h3 className="font-bold text-xl line-clamp-1"><span itemprop="jobTitle">Developpeur Fullstack Vue/Node/TypeScript</span></h3>
          <h4><a href="https://opera-energie.com" target="_blank" rel="noopener noreferrer"><span itemprop="name" className="text-lg font-semibold">Solotech</span></a>, Nantes (44)</h4>

          <p itemprop="description" class="nospace">
          Intervention au sein d'un groupe de 5 développeurs Client
          canadien, le projet consistait à stabiliser une première version
          d'une plateforme de partage de flux pour des événements
          internationaux avec salle de
          réunion virtuelle, partage de documents, chat interne global et
          privé.
            </p><div className=" mt-5 keyword">
              <p>Réalisation technique :</p>
              <ul className="mt-5">
              <li>✔ Participation/Animation cérémonies Agile</li>
              <li>✔ Analyse des besoins techniques</li>
              <li>✔ Propositions de choix techniques/Architecture</li>
              <li>✔ Audit Technique et édition de documentation</li>
              <li>✔ Analyse du projet existant à mon arrivée</li>
              <li>✔ Chiffrage/Estimations des difficultés techniques de
              développements</li>
              <li>✔ Relecture et contrôle de la qualité de code de 5 développeurs</li>
              <li>✔ Implémenter les Tests d'intégration Cypress sur 3 pages, 10
              composants différents</li>
              <li>✔ Débogage continue de l'application</li>
              <li>✔ Factorisation de certaines fonctionnalités préalablement
              implémenté</li>
              <li>✔ Implémenter un graphique Primevue représentant les
              connexions et déconnexions des utilisateurs sur des périodes
              données de chaque évènements, téléchargeable</li>
              <li>✔ Intégration d'une Map Leaflet afin de visualiser les différents
              point de connexions autour du monde, téléchargeable</li>
              <li>✔ CRUD côté admin pour gérer les nouveaux/actuels utilisateurs</li>
              </ul>
            </div>
          <p></p>
        </li>

        <li className="mb-[1.25em]" itemscope="" itemtype="http://schema.org/Organization">
          <h5 className="float-right text-lg">01/2021 - 05/2022</h5>
          <h3 className="font-bold text-xl line-clamp-1"><span itemprop="jobTitle">Developpeur Fullstack Réact/Node/TypeScript</span></h3>
          <h4><span itemprop="name" className="text-lg font-semibold">JBS</span>, Nantes (44)</h4>

          <p itemprop="description" class="nospace">
          Au sein d'une équipe de 2 développeurs, Solution Saas
          "Iziqo.fr" de développé en 1an et demi.
          Application intégrant toutes leurs difficultés techniques
          communiquant directement avec l'ERP de la société
          Menuiserie pour ses particuliers et Artisan.
          Une partie Menuiserie où la société menuiserie peut
          cataloguer ses produits et définir/configurer les problèmes
          métiers de chacune de leurs menuiseries.
          Une seconde partie Artisan où le Client/Artisan va pouvoir
          créer son Devis et recevoir sa menuiserie en chantier ou chez
          un particulier.

            </p><div className=" mt-5 keyword">
              <p>Réalisation technique :</p>
              <ul className="mt-5">
              <li>✔ Participation/Animation cérémonies Agile</li>
              <li>✔ Analyse des besoins techniques</li>
              <li>✔ Propositions de choix techniques/Architecture</li>
              <li>✔ Audit Technique et édition de documentation</li>
              <li>✔ Chiffrage/Estimations des difficultés techniques de
              développements</li>
              <li>✔ Relecture et contrôle de la qualité de code du second
              développeur</li>
              <li>✔ Prise en charge de la passation de connaissances pour mon
              remplaçant</li>
              <li>✔ Intégration HTML/CSS</li>
              <li>✔ Création d'un outil réservé à la représentation 2D en SVG des
              produits</li>
              <li>✔ Formation d'un nouveau développeur sur le projet pendant 6
              mois</li>
              <li className="mb-1">✔ Configuration d'un catalogue de produits</li>
              Configuration de modules permettant l'intégration des
              difficultés métier de l'entreprise:
              <li className="ml-4">✔ Limites dimensionnelles</li>
              <li className="ml-4">✔ Contraintes</li>
              <li className="ml-4">✔ Cotes</li>
              <li className="ml-4">✔ Prix</li>
              <li className="ml-4">✔ Surfaces</li>
              
              </ul>
            </div>
          <p></p>
        </li>
      </ul>
    </div>
  )
}

export default Index