import React from 'react'

const Index = () => {
  return (
    <div role="main">
      <div className="">
        <h1 className="lg:text-[3.65em] text-5xl text-gray-900">
        <img width={48} src="/images/helmet.png" alt="Github" />
          <strong>Léo</strong> GOUBARD
        <br/>
        Développeur Fullstack Js | Ts | Python
        <br/>
        <br/>
        
        </h1>
      </div>
    </div>
  )
}

export default Index