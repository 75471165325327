import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Navbar from './components/Navbar';
import Infos from './components/Infos';
import Home from './components/Home';
import Blog from './pages/Blog';
import NoMatch from './pages/404';
import Curriculum from './pages/Curriculum';


const App = () => {
  return (
    <div className="w-[65%] mx-auto block">
      <Navbar />
      <Router>
        <Routes>
          <Route path="/" element={ <Home />} />
          <Route path="/blog" element={ <Blog />} />
          <Route path="/cv" element={ <Curriculum />} />
          <Route path="*" element={ <NoMatch />} />
        </Routes>
      </Router>
      
      <Infos />
    </div>
  );
}

export default App;
