import React from 'react'
import Helmet from "helmet";

const Index = () => {

  return (
    <div role="main">
      <Helmet>
        Leo Goubard Développeur Fullstack - Blog
        <meta name="description" content="DDD (Domain Driven Design), Principe SOLID et autres articles autour du sujet du developpement" />
      </Helmet>
        <div itemScope="" itemType="http://schema.org/Blog">
          <ul itemScope="" className="odd:my-5 divide-y divide-black grid gap-6 grid-cols-1" itemType="http://schema.org/BlogPosts">
                <li >
                  <article itemScope="" className="hover:bg-[#2c2c2c] p-2 hover:text-white cursor-pointer" itemType="http://schema.org/BlogPosting" >
                    <a href="https://medium.com/@goubardleo/domain-driven-design-76b2615195c1" target="_blank" rel="noreferrer" itemProp="url">
                      <h2 itemProp="headline" className="font-bold text-3xl">
                      Domain Driven Design (DDD)
                      </h2><br />
                      <time dateTime="2023-11-19" itemProp="datePublished">
                        19 novembre 2023
                      </time>

                      <span className="text-xl">
                        <p>Le Domain Driven Design (DDD) est une technique de conception logicielle orientée métier popularisé par Eric Evans à travers son ouvrage éponyme publié en 2003.
                        Cette méthodologie se distingue par son orientation métier, visant à créer des systèmes logiciels cohérents et alignés sur les enjeux métier.</p>
                      </span>
                    </a>
                  </article>
                </li>
                <li >
                  <article itemScope=""  className="hover:bg-[#2c2c2c] p-2 hover:text-white cursor-pointer" itemType="http://schema.org/BlogPosting" >
                    <a target="_blank" href="https://medium.com/@goubardleo/les-principes-solid-7111d2131b1c" rel="noreferrer" itemProp="url">
                      <h2 itemProp="headline" className="font-bold text-3xl">
                      Les Principes SOLID

                      </h2>
                      <br />
                      <time dateTime="2023-11-24" itemProp="datePublished">
                        24 novembre 2023
                      </time>

                      <span className="text-xl">
                        <p>Solidifier le Code : Une Exploration Approfondie des Principes SOLID pour une Conception Logicielle Robuste et Évolutive.</p>
                      </span>
                    </a>
                  </article>
                </li>  
                <li >
                  <article itemScope=""  className="hover:bg-[#2c2c2c] p-2 hover:text-white cursor-pointer" itemType="http://schema.org/BlogPosting" >
                    <a target="_blank" href="https://medium.com/@goubardleo/larchitecture-hexagonale-un-monde-de-d%C3%A9couplage-et-de-flexibilit%C3%A9-3061d25586ae" rel="noreferrer" itemProp="url">
                      <h2 itemProp="headline" className="font-bold text-3xl">
                      L’Architecture Hexagonale

                      </h2>
                      <br />
                      <time dateTime="2023-12-09" itemProp="datePublished">
                        09 Décembre 2023
                      </time>

                      <span className="text-xl">
                        <p>Dans le domaine complexe de l’architecture logicielle, une approche innovante émerge, offrant une solution élégante aux défis de dépendance au sein d’une application: l’Architecture Hexagonale. Souvent appelée Ports and Adapters Pattern, cette méthodologie offre une perspective unique, propulsée par la vision d’Alistair Cockburn, un pionnier dans le domaine du développement logiciel agile.</p>
                      </span>
                    </a>
                  </article>
                </li>    
          </ul>
        </div>
      </div>
  )
}

export default Index