import React from 'react'
import Helmet from "helmet";

const Index = () => {
  return (
    <div className="flex flex-col items-center h-96 justify-center noMatch">
      <Helmet>
        Leo Goubard Développeur Fullstack - 404
        <meta name="description" content="Vous vous êtes perdu" />
      </Helmet>
      <h1 className="text-3xl md:text-4xl font-extrabold text-white">page not found</h1>
      <h1 className="text-4xl md:text-4xl font-extrabold text-white">404</h1>
    </div>
  )
}

export default Index