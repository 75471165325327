import React from 'react'

const Index = () => {
  return (

	<div className="max-w-sm rounded-lg mt-16">

		<div className="flex flex-col md:flex-row items-center">
      <figure>
			  <img className="mb-3 w-24 h-24 shadow-lg shield" src="/images/profile.jpg" alt="Léo Goubard" />
      </figure>
      <div className="flex flex-col mx-5">

      <address className="block">
        <span className="mb-1 text-xl font-medium text-gray-900">Léo goubard</span>
        <span className="mb-1 text-xl font-medium text-gray-900 px-3 items-center">⸸</span>
        <span className="mb-1 text-xl font-medium text-gray-900">Tours</span>
      </address>
      <div>
        <a href="mailto:goubardleo@gmail.com" itemProp="email">
        goubardleo[at]gmail.com
        </a>
      </div>
      </div>

		</div>
	</div>
  )
}

export default Index;