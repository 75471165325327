import React from 'react'
import BlogList from '../../components/BlogList';
const Index = () => {
  return (
    <div>
      <BlogList />
    </div>
  )
}

export default Index